import { firebase } from '@vette/frontend-utils';
import { NotFound } from '@vette/pages';
import { DashboardLayout } from '@vette/ui-components';

export const CustomNotFound = () => {
  return (
    <firebase.FirebaseClaims fallback={<NotFound />}>
      {({ id }) =>
        id ? (
          <DashboardLayout fullLogo>
            <NotFound />
          </DashboardLayout>
        ) : (
          <NotFound />
        )
      }
    </firebase.FirebaseClaims>
  );
};

export default CustomNotFound;
